window.Vue = require('vue');

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
 
Vue.component('Newsletter', require('../../componentes/sitio/Newsletter.vue').default);

Vue.config.productionTip = false;
Vue.config.devtools = false;

const newsletter = new Vue({
    el: '#vue-newsletter',
});