<template>
    <div class="col-11 col-md-8 mx-auto text-center">
        <h2 class="font-weight-bold mb-5"><span class="text-primary">//</span> Newsletter</h2>
        <form method="POST" id="formulario-newsletter" @submit.prevent="sendMessage">
            <input type="hidden" name="_token" :value="csrf">
            <div class="row">
                <div class="col-12 col-md-8">
                    <div class="form-group">
                        <input
                            type="text"
                            placeholder="Email"
                            v-model.trim="$v.form.email.$model"
                            name="email"
                            :class="
                                !$v.form.email.$anyError
                                    ? 'form-control'
                                    : 'form-control form-error'
                            "
                        />
                    <div class="error-data" v-if="$v.form.email.$dirty">
                        <span v-if="!$v.form.email.required"
                            >Campo necesario</span
                        >
                        <span v-if="!$v.form.email.email">Email inválido</span>
                    </div>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <button
                        :disabled="formLoading || $v.form.$invalid"
                        type="submit"
                        class="btn btn-primary btn-block bg-main-color general-btn d-flex justify-content-center align-items-center"
                    >
                        {{ btnText }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import { required, email } from 'vuelidate/lib/validators'
    export default {
        name: "Newsletter",
        props: [],
        data: function() {
            return  {
                formLoading: false,
                btnText: 'Enviar',
                form:{
                    email: ''
                },
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            }
        },
        mounted() {},
        methods:{
            sendMessage () {
                this.$v.form.$touch()
                if (!this.$v.form.$invalid) {
                    this.btnText = 'Enviando...'
                    this.formLoading = true
                    const ruta = 'newsletter-formulario'
                    $('#formulario-newsletter').attr('action', ruta).submit()
                }
            },
        },
        validations: {
            form: {
                email: {
                    required,
                    email
                }
            }
        }
    }
</script>

<style scoped>
    .general-btn {
        margin: 3px 0;
    }
</style>
